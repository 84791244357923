var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-expander-base',{attrs:{"title":_vm.task.cve,"description":_vm.task.summary,"package-count":_vm.showPackageCount ? _vm.packageCount : undefined},scopedSlots:_vm._u([(_vm.task.status === _vm.TaskDeviceStatuses.In_Progress)?{key:"extra-buttons",fn:function(){return [_c('ax-button',{attrs:{"icon":"","aria-label":_vm.$t('tasks.remediations.refresh:aria')},on:{"click":function($event){return _vm.$emit('refresh-requested')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiRefresh))])],1)]},proxy:true}:null,{key:"cornerContent",fn:function(){return [_c('ax-task-status-indicator',{attrs:{"task":_vm.task}})]},proxy:true},{key:"default",fn:function(){return [(_vm.task.status !== _vm.TaskDeviceStatuses.Pending)?_c('div',{staticClass:"ax-cve-expander__remediation-info-container"},[_c('div',{staticClass:"ax-cve-expander__remediation-info-explanation-container"},[(_vm.explanationData.icon)?_c('v-icon',{attrs:{"size":"6rem"}},[_vm._v(" "+_vm._s(_vm.explanationData.icon)+" ")]):_vm._e(),_c('div',{staticClass:"ax-cve-expander__remediation-info-explanation"},[_c('h3',[_vm._v(_vm._s(_vm.explanationData.title))]),_c('p',[_vm._v(_vm._s(_vm.explanationData.description))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( 'tasks.remediations.details.expander.explanations.filterQuickTip' ))+" ")])])],1),_c('ax-device-remediation-doughnut',{attrs:{"devices":_vm.devices},model:{value:(_vm.hiddenDeviceStatuses),callback:function ($$v) {_vm.hiddenDeviceStatuses=$$v},expression:"hiddenDeviceStatuses"}})],1):_vm._e(),_c('ax-searchable-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredDevices},scopedSlots:_vm._u([{key:"item.custom_name",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.custom_name))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.name))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('ax-device-status-indicator',{attrs:{"status":item.status}})]}},{key:"item.ip_addrs_private",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"fs-mask"},[_vm._v(_vm._s(item.ip_addrs_private ? item.ip_addrs_private.join(', ') : ''))])]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),(_vm.task.status === _vm.TaskDeviceStatuses.Pending)?_c('ax-button',{attrs:{"disabled":_vm.selectedItems.length === 0,"mode":"primary"},on:{"click":function($event){return _vm.$emit('devices-selected', _vm.selectedItems)}}},[_vm._v(" "+_vm._s(_vm.buttonLabel)+" ")]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }