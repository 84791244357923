import {
  mdiAccountClockOutline,
  mdiCancel,
  mdiCheck,
  mdiClockAlertOutline,
  mdiExclamationThick,
  mdiHelpCircleOutline,
  mdiProgressClock,
} from '@mdi/js';
import { CosmosDarkColors } from '@ax/cosmos/utils/colors';
import { TaskDetailsDevice, TaskDeviceStatuses } from '@/models/task';

export const UNKNOWN_COLOR = CosmosDarkColors.Warning;

export const STATUS_TO_COLOR = new Map([
  [TaskDeviceStatuses.Pending, CosmosDarkColors.ContentDark],
  [TaskDeviceStatuses.In_Progress, CosmosDarkColors.Teal],
  [TaskDeviceStatuses.Success, CosmosDarkColors.Success],
  [TaskDeviceStatuses.Failed, CosmosDarkColors.DataRed],
  [TaskDeviceStatuses.Timed_Out, CosmosDarkColors.DataOrange],
  [TaskDeviceStatuses.Canceled, CosmosDarkColors.DataPink],
]);

export const UNKNOWN_TRANSLATION_KEY = 'general.statuses.unknown';

export const STATUS_TO_TRANSLATION_KEY = new Map([
  [TaskDeviceStatuses.Pending, 'general.statuses.notStarted'],
  [TaskDeviceStatuses.In_Progress, 'general.statuses.inProgress'],
  [TaskDeviceStatuses.Success, 'general.statuses.success'],
  [TaskDeviceStatuses.Failed, 'general.statuses.failed'],
  [TaskDeviceStatuses.Timed_Out, 'general.statuses.timedOut'],
  [TaskDeviceStatuses.Canceled, 'general.statuses.canceled'],
]);

export const UNKNOWN_ICON = mdiHelpCircleOutline;

export const STATUS_TO_ICON = new Map([
  [TaskDeviceStatuses.Pending, mdiAccountClockOutline],
  [TaskDeviceStatuses.In_Progress, mdiProgressClock],
  [TaskDeviceStatuses.Success, mdiCheck],
  [TaskDeviceStatuses.Failed, mdiExclamationThick],
  [TaskDeviceStatuses.Timed_Out, mdiClockAlertOutline],
  [TaskDeviceStatuses.Canceled, mdiCancel],
]);

export function allRemediationsSucceeded(devices: TaskDetailsDevice[]) {
  return devices.every(({ status }) => status === TaskDeviceStatuses.Success);
}

export function someRemediationsSucceeded(devices: TaskDetailsDevice[]) {
  return devices.some(({ status }) => status === TaskDeviceStatuses.Success);
}

export function noRemediationsSucceeded(devices: TaskDetailsDevice[]) {
  return !someRemediationsSucceeded(devices);
}
